import { CriteriaBase } from '@/shared/models';

/**
 * Modèle pour les critères de création de dépôt.
 * */
export class CreationDepotCriteria extends CriteriaBase {

    // Nom du dépôt.
    public nomDepot: string = '';

    // Date du dépôt.
    public dateDepot: string = '';

    // Contenu.
    public contenu: string = '';

}