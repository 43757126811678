import { Component, Mixins } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import template from './CreationDepot.Template.vue';
import CreationDepotValidator from './CreationDepot.Validator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { CreationDepotCriteria } from './models/CreationDepotCriteria.model';
import CeeDatePicker from '@/components/CeeDatePicker.vue';

@Component({
    ...template,
    name: 'CreationDepot',
    components: {
        CeeDatePicker,
        ValidationProvider,
        ValidationObserver,
    },
})
export default class FichiersPncee extends Mixins(CreationDepotValidator, GrilleMixin) {
    public $refs!: {
        form: HTMLFormElement,
    };

    // Loader.
    private loadingCreationDepot: boolean = false;

    // Critères de recherches avancés.
    private criteria: CreationDepotCriteria = new CreationDepotCriteria();

    /**
     * constructor.
     */
    constructor() {
        super();
    }

    /**
     * Création d'un nouveau dépôt.
     */
    private creerDepot(): void {
        if (this.$refs.form.validate()) {
            this.loadingCreationDepot = true;
            this.$http.post('/depot/creerDepot', this.criteria)
                .finally(() => this.loadingCreationDepot = false);
        }
    }

    /**
     * Lecture du fichier CSV afin de récupérer son contenu inline dans une string pour alimenter les criterias postées pour la création du dépôts.
     * @param file
     */
    private changeCsvToString(file: File) {
        let lines: any = "";
        let currentLine: any = "";
        let csv: any = "";
        let headers: any = "";
        let result: any = "";
        let reader: any = new FileReader();

        reader.readAsBinaryString(file);
        reader.onload = () => {
            csv = reader.result;
            lines = csv.split("\r" + "\n");

            for (let i = 0; i < lines.length; i++) {
                if (!lines[i])
                    continue
                let obj = "";
                currentLine = lines[i];
                const re = /"/g;
                currentLine = re[Symbol.replace](currentLine, '');
                obj = obj.concat(currentLine);

                for (let j = 0; j < headers.length; j++) {
                    obj = obj.concat(headers[j].trim(), currentLine[j].trim());
                }

                result = result.concat(obj);
                // Retrait manuel du BOM.
                result = result.replace('ï»¿', '')
            }
            this.criteria.contenu = result;
        };
    }
}

